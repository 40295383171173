import React, { useState, useEffect } from "react";
import axiosService from "./network/axiosService";

interface User {
  name: string;
  email: string;
  // Add other relevant user fields
}

const ProfilePage: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosService.get("/profile/my"); // Adjust the endpoint as needed
        const u = {
          "name": response.data.data.name,
          "email": response.data.data.email
        } as User;
        setUser(u);
      } catch (err) {
        setError("Error fetching user data");
        // Log or handle the error as needed
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData()
  }, []);

  if (isLoading) {
    return <div>Loading profile...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!user) {
    return <div>No user data found.</div>;
  }

  return (
    <div className="profile-page">
      <h1>User Profile</h1>
      <div className="profile-details">
        <p><strong>Name:</strong> {user.name}</p>
        <p><strong>Email:</strong> {user.email}</p>
        {/* Render other user details here */}
      </div>
    </div>
  );
};

export default ProfilePage;
