import React from 'react';

const Header: React.FC = () => {
  return (
    <div className="text-center my-5 flex items-center justify-center">
      <img src="https://getbootstrap.com/docs/5.0/assets/brand/bootstrap-logo.svg" className="" alt="logo" width="60" />
    </div>
  );
};

export default Header;
