import React, { ChangeEvent, useState } from 'react';

interface DateInputProps {
  onDateChange: (date: { year: number; month: number; day: number }) => void;
}

const DateInput: React.FC<DateInputProps> = ({ onDateChange }) => {
  const [date, setDate] = useState({ year: 2024, month: 1, day: 1 });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDate((prevDate) => {
      const newDate = { ...prevDate, [name]: parseInt(value, 10) };
      onDateChange(newDate);
      return newDate;
    });
  };

  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '300px',
      margin: '0 auto',
    },
    input_year: {
      width: '80px',
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    input: {
      width: '60px',
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
  };

  return (
    <div style={styles.container} className="">
      <input
        type="number"
        name="year"
        min="1900"
        max="2100"
        placeholder="Year"
        value={date.year}
        onChange={handleChange}
        style={styles.input_year}
      />
      <text className="flex align-items-center justify-center mx-2">年</text>
      <input
        type="number"
        name="month"
        min="1"
        max="12"
        placeholder="Month"
        value={date.month}
        onChange={handleChange}
        style={styles.input}
      />

      <text className="flex align-items-center justify-center mx-2">月</text>
      <input
        min={1}
        max={31}
        type="number"
        name="day"
        placeholder="Day"
        value={date.day}
        onChange={handleChange}
        style={styles.input}
      />
      <text className="flex align-items-center justify-center mx-2">日</text>
    </div>
  );
};

export default DateInput;