import React from "react";

// Define the shape of a comment
export interface UserCommentProps {
    id: number;
    text: string;
    author: string;
    date: string;
    score: number;
}

const UserComment: React.FC<UserCommentProps> = ({author, text, date, score}) => (
    <li className="bg-white shadow rounded-lg p-4">
        <div className="flex justify-between items-center mb-2">
            <span className="font-semibold text-gray-800">{author}</span>
            <span className="text-yellow-500 font-bold">{'⭐'.repeat(score)}</span>
        </div>
        <p className="text-gray-600">{text}</p>
        <small className="text-gray-400 block mt-2">Posted on: {new Date(date).toLocaleDateString()}</small>
    </li>
);


export default UserComment;