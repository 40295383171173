import React from 'react';
import { Dimensions, getHeightViaWidth, getIconWidthViaHeight, IdCardInfo } from '../IDCardConst';


const icon_height = 120;

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    border: '2px solid #000',
    borderRadius: '10px',
    backgroundColor: 'white',
    width: Dimensions.widthPx + 'px',
    padding: '30px',
    paddingTop: '40px',
    height: getHeightViaWidth(Dimensions.widthPx) + 'px',
  },

  body: {
    display: 'flex',
  },

  image: {
    width: getIconWidthViaHeight(icon_height) + 'px',
    height: icon_height + 'px',
  },

  backTitleContainer: {
    height: icon_height,
    paddingLeft: '18px',
  },

  backTitle: {
    fontFamily: 'Arial, sans-serif',
    fontSize: '28px',
    fontWeight: 'bold',
    lineHeight: '1.8',
    height: icon_height,
    alignContent: 'center',
  },

  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '100px',
    fontFamily: 'Arial, sans-serif',
  },

  Row1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },

  Row1Label: {
    fontWeight: 'bold',
    flexBasis: '150px',
    flexShrink: 0,
    textAlign: 'right',
    paddingRight: '10px',
    fontSize: '16px',
    marginRight: '0px', // Adjust the spacing between label and content
  },
  Row1Content: {
    flexGrow: 1,
    fontSize: '16px',
    textAlign: 'left',
    alignContent: 'flex-start',
    position: 'relative',
    boxSizing: 'border-box',
  },

  Row2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: '10px',
  },

};


const BackPageComponent: React.FC<{ cardInfo: IdCardInfo }> = ({ cardInfo }) => {
  return (
    <div style={styles.container}>
      <div style={styles.body}>
        <img src="/images/china_icon.png" alt="Description" style={styles.image} />
        <div>
          <div style={styles.backTitleContainer}>
            <text style={styles.backTitle}>Citizen
              Identity Card of
              the People's Republic
              of China
            </text>
          </div>
          <div style={styles.bodyContainer}>
            <div style={styles.Row1}>
              <p style={styles.Row1Label}>Issuing Authority:</p>
              <p style={styles.Row1Content}>{cardInfo.issuingAuthority}</p>
            </div>
            <div style={styles.Row2}>
              <text style={styles.Row1Label}>Valid Period:</text>
              <text style={styles.Row1Content}>{cardInfo.validPeriod}</text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackPageComponent;
