import React from 'react';
import { useFetchDiscussions } from './hooks/useFetchDiscussions';

const GraphQLAppHomePage: React.FC = () => {
  const { discussions, isLoading, error } =
    useFetchDiscussions(999);

  if (isLoading) {
    return <div>Loading discussions...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="homepage">
      <h1>Title: Latest Discussions</h1>
      <div className="discussion_list">
        {discussions.map((discussion) => (
          <div key={discussion.id} className="discussion">
            <p>email: {discussion.title}</p>
            <p>author: {discussion.author}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GraphQLAppHomePage;