const Dimensions = {
  widthCM: 85.6,
  heightCM: 54,
  widthPx: 673,
  heightPx: 425,
  iconHeightPx: 160,
  iconWidthPx: 203,
  avatarWidthPx: 358,
  avatarHeightPx: 441,
};


function getIconWidthViaHeight(height: number): number {
  return height * Dimensions.iconWidthPx / Dimensions.iconHeightPx;
}

function getHeightViaWidth(width: number): number {
  return width * Dimensions.heightCM / Dimensions.widthCM;
}

function getAvatarWidthViaHeight(height: number): number {
  return height * Dimensions.avatarWidthPx / Dimensions.avatarHeightPx;
}


export function formatDate(year: number, month: number, day: number): string {
  // Create a Date object using the extracted year, month, and day
  const date = new Date(Number(year), Number(month) - 1, Number(day));

  // Define an array of month names
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  // Get the month name, day, and year from the Date object
  const monthName = monthNames[date.getMonth()];
  const formattedDay = date.getDate();
  const formattedYear = date.getFullYear();

  // Return the formatted date string
  return `${monthName} ${formattedDay}, ${formattedYear}`;
}

export function singleNumberToDouble(number: number): string {
  return number < 10 ? `0${number}` : `${number}`;

}

interface PersonInfo {
  name: string;
  sex: string;
  ethnicity: string;
  dateOfBirth: string;
  address: string;
  citizenIdNumber: string;
  avatar: string;
}

export interface IdCardInfo extends PersonInfo {
  issuingAuthority: string;
  validPeriod: string;
}


export {
  Dimensions,
  getHeightViaWidth,
  getIconWidthViaHeight,
  getAvatarWidthViaHeight,
};