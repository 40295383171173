import React, { ChangeEvent, useState } from 'react';
import DateInput from '../../../shared/widgets/DateInput';
import Label from '../../../shared/widgets/Label';

export interface IDCardFormData {
  name: string;
  sex: string;
  ethnicity: string;
  dateOfBirth: {
    year: number;
    month: number;
    day: number;
  };
  address: string;
  avatar: string;
  idNumber: string;
  issuingAuthority: string;
  validPeriod: {
    startYear: number;
    startMonth: number;
    startDay: number;
    endYear: number;
    endMonth: number;
    endDay: number;
  };
}

interface IDCardTranslationFormProps {
  onIDCardFormDataChange: (data: IDCardFormData) => void;
}

const IDCardTranslationForm: React.FC<IDCardTranslationFormProps> = ({ onIDCardFormDataChange }) => {
  const [IDCardFormData, setIDCardFormData] = useState<IDCardFormData>({
    name: '',
    sex: '',
    ethnicity: '',
    dateOfBirth: {
      year: 2024,
      month: 1,
      day: 1,
    },
    address: '',
    idNumber: '',
    avatar: '',
    issuingAuthority: '',
    validPeriod: {
      startYear: 2024,
      startMonth: 1,
      startDay: 1,
      endYear: 2024,
      endMonth: 1,
      endDay: 1,
    },
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setIDCardFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      onIDCardFormDataChange(updatedData);
      return updatedData;
    });
  };

  const handleNumberChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: keyof IDCardFormData['dateOfBirth'] | keyof IDCardFormData['validPeriod'],
  ) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, '');

    setIDCardFormData((prevData) => {
      let updatedData: IDCardFormData;
      if (name.includes('dateOfBirth')) {
        updatedData = {
          ...prevData,
          dateOfBirth: {
            ...prevData.dateOfBirth,
            [field]: numericValue,
          },
        };
      } else if (name.includes('validPeriod')) {
        updatedData = {
          ...prevData,
          validPeriod: {
            ...prevData.validPeriod,
            [field]: numericValue,
          },
        };
      } else {
        return prevData;
      }
      onIDCardFormDataChange(updatedData);
      return updatedData;
    });
  };

  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      maxWidth: '400px',
      margin: '0',
      padding: '20px',
      backgroundColor: '#f5f5f5',
      borderRadius: '5px',
    },
    heading: {
      fontSize: '24px',
      marginBottom: '20px',
      textAlign: 'center',
    },
    inputContainer: {
      marginBottom: '15px',
    },
    input: {
      width: '100%',
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    select: {
      width: '100%',
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      appearance: 'none',
      backgroundColor: 'white',
    },
    dateContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dateInput: {
      width: '30%',
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>身份证信息：英文翻译</h2>
      <div style={styles.inputContainer}>
        <Label text="姓名(拼音):" htmlFor="name" />
        <input type="text" name="name" onChange={handleChange} style={styles.input} defaultValue={'Zhang San'} />
      </div>
      <div style={styles.inputContainer}>
        <Label text="性别:" htmlFor="sex" />
        <select name="sex" onChange={handleChange} style={styles.select}>
          <option value="Male">男</option>
          <option value="Female">女</option>
        </select>
      </div>
      <div style={styles.inputContainer}>
        <Label text="民族(拼音):" htmlFor="ethnicity" />
        <input type="text" name="ethnicity" onChange={handleChange} style={styles.input} defaultValue={'Han'} />
      </div>
      <div style={styles.inputContainer}>
        <Label text="出生日期:" htmlFor="dateOfBirth" />
        <DateInput onDateChange={(date) => console.log(date)} />
      </div>
      <div style={styles.inputContainer}>
        <Label text="住址:" htmlFor="address" />
        <input type="text" name="address" onChange={handleChange} style={styles.input} />
      </div>
      <div style={styles.inputContainer}>
        <Label text="身份证号码:" htmlFor="idNumber" />
        <input type="text" name="idNumber" onChange={handleChange} style={styles.input} />
      </div>
      <div style={styles.inputContainer}>
        <Label text="证件头像:" htmlFor="avatar" />
        <input type="text" name="avatar" onChange={handleChange} style={styles.input} />
      </div>
      <div style={styles.inputContainer}>
        <Label text="签发机关:" htmlFor="issuingAuthority" />
        <input type="text" name="issuingAuthority" onChange={handleChange} style={styles.input} />
      </div>
      <div style={styles.inputContainer}>
        <Label text="有效期限:" htmlFor="validPeriod" />
        <DateInput onDateChange={(date) => console.log(date)} />
        <span className="mx-2">到</span>
        <DateInput onDateChange={(date) => console.log(date)} />
      </div>
    </div>
  );
};

export default IDCardTranslationForm;