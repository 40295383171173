import React, { useState } from "react";
import { Link } from "react-router-dom";

interface RegisterFormState {
  name: string;
  email: string;
  password: string;
  isNameValid: boolean;
  isEmailValid: boolean;
  isPasswordValid: boolean;
}


const RegisterForm: React.FC = () => {
  const [formState, setFormState] = useState<RegisterFormState>({
    name: "",
    email: "",
    password: "",
    isNameValid: true,
    isEmailValid: true,
    isPasswordValid: true
  });

  const validateEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const validatePassword = (password: string) => {
    return password.length >= 6;
  };

  const validateName = (name: string) => {
    return name.length > 0;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let isNameValid = formState.isNameValid;
    let isEmailValid = formState.isEmailValid;
    let isPasswordValid = formState.isPasswordValid;

    if (name === "name") {
      isNameValid = validateName(value);
    } else if (name === "email") {
      isEmailValid = validateEmail(value);
    } else if (name === "password") {
      isPasswordValid = validatePassword(value);
    }

    setFormState({
      ...formState,
      [name]: value,
      isNameValid,
      isEmailValid,
      isPasswordValid
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = validateName(formState.name) && validateEmail(formState.email) && validatePassword(formState.password);

    if (!isFormValid) {
      setFormState({
        ...formState,
        isNameValid: validateName(formState.name),
        isEmailValid: validateEmail(formState.email),
        isPasswordValid: validatePassword(formState.password)
      });
      return;
    }

    // Implement registration logic here
    console.log("Form Submitted", formState);
  };


  return (
    <div className="card shadow-lg">
      <div className="card-body p-5">
        <h1 className="fs-4 card-title fw-bold mb-4">Register</h1>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className="mb-3">
            <label className="mb-2 text-muted" htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              className={`form-control ${!formState.isNameValid ? "is-invalid" : ""}`}
              name="name"
              value={formState.name}
              required
              autoFocus
              onChange={handleInputChange}
            />
            {!formState.isNameValid && <div className="invalid-feedback">Name is required</div>}
          </div>

          <div className="mb-3">
            <label className="mb-2 text-muted" htmlFor="email">Email Address</label>
            <input
              id="email"
              type="email"
              className={`form-control ${!formState.isEmailValid ? "is-invalid" : ""}`}
              name="email"
              value={formState.email}
              required
              onChange={handleInputChange}
            />
            {!formState.isEmailValid && <div className="invalid-feedback">Invalid email address</div>}
          </div>

          <div className="mb-3">
            <label className="mb-2 text-muted" htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              className={`form-control ${!formState.isPasswordValid ? "is-invalid" : ""}`}
              name="password"
              value={formState.password}
              required
              onChange={handleInputChange}
            />
            {!formState.isPasswordValid &&
              <div className="invalid-feedback">Password must be at least 6 characters long</div>}
          </div>

          <div className="align-items-center d-flex">
            <button type="submit" className="btn btn-primary flex-grow-1">Register</button>
          </div>
        </form>
      </div>
      <div className="card-footer py-3 border-0">
        <div className="text-center">
          Already have an account? <Link to="/login" className="text-dark">Login</Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
