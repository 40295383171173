import React from 'react';
import { Dimensions, getAvatarWidthViaHeight, getHeightViaWidth, IdCardInfo } from '../IDCardConst';
import AvatarEditor from 'react-avatar-editor';


const FrontPageComponent: React.FC<{ personInfo: IdCardInfo }> = ({ personInfo }) => {

  const avatarHeight = Dimensions.heightPx * 0.6;

  const styles: { [key: string]: React.CSSProperties } = {
    idCard: {
      border: '2px solid #000',
      borderRadius: '10px',
      backgroundColor: 'white',
      width: Dimensions.widthPx + 'px',
      padding: '30px',
      paddingTop: '40px',
      height: getHeightViaWidth(Dimensions.widthPx) + 'px',
      fontSize: '18px',
    },
    idHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },
    idContent: {
      display: 'flex',
    },
    idTextArea: {
      flex: 1,
      marginRight: '20px',
    },
    idPhoto: {
      width: getAvatarWidthViaHeight(avatarHeight) + 'px',
      height: avatarHeight + 'px',
      backgroundColor: '#ddd',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center', // Add this line to vertically center the avatar
    },
    idPhotoImage: {
      backgroundColor: '#ddd',
      maxWidth: '100%',
      height: 'auto',
      display: 'flex', // Add this line
      justifyContent: 'center', // Add this line to horizontally center the avatar
      alignItems: 'center', // Add this line to vertically center the avatar
    },

    idDetailsDiv: {
      textAlign: 'left',
      marginBottom: '10px',
      paddingBottom: '20px',
    },
    icdDetailsDiv: {
      textAlign: 'left',
      marginBottom: '10px',
      marginTop: '20px',
    },
    label: {
      fontSize: '16px',
      fontWeight: 'bold',
      marginRight: '5px',
      paddingRight: '5px',

    },
    label2: {
      marginLeft: '40px',
      fontSize: '16px',
      fontWeight: 'bold',
      marginRight: '5px',
      paddingRight: '5px',

    },

    value: {
      marginRight: '10px',
      letterSpacing: '0px',
    },
    valueNumber: {
      letterSpacing: '2px',
    },
  };

  return (
    <div style={styles.idCard}>
      <div style={styles.idContent}>
        <div style={styles.idTextArea}>
          <div style={styles.idDetailsDiv}>
            <span style={styles.label}>Name:</span>
            <span style={styles.value}>{personInfo.name}</span>
          </div>
          <div style={styles.idDetailsDiv}>
            <span style={styles.label}>Sex:</span>
            <span style={styles.value}>{personInfo.sex}</span>
            <span style={styles.label2}>Ethnicity:</span>
            <span style={styles.value}>{personInfo.ethnicity}</span>
          </div>
          <div style={styles.idDetailsDiv}>
            <span style={styles.label}>Date of Birth:</span>
            <span style={styles.value}>{personInfo.dateOfBirth}</span>
          </div>
          <div style={styles.idDetailsDiv}>
            <span style={styles.label}>Residential Address:</span>
            <span style={styles.value}>{personInfo.address}</span>
          </div>
        </div>
        <div style={styles.idPhoto}>
          <div style={styles.idPhoto}>
            <AvatarEditor
              image={personInfo.avatar}
              width={getAvatarWidthViaHeight(avatarHeight)}
              height={avatarHeight}
              border={0}
              borderRadius={0}
              color={[255, 255, 255, 0.6]}
              scale={1}
              rotate={0}
              style={styles.idPhotoImage}
            />
          </div>
        </div>
      </div>
      <div style={styles.icdDetailsDiv}>
        <span style={styles.label}>Citizen ID Number:</span>
        <span style={styles.valueNumber}>{personInfo.citizenIdNumber}</span>
      </div>
    </div>
  );
};

export default FrontPageComponent;