import React from "react";
import Header from "./components/Header"; // Reuse the Header component
import ForgotPasswordForm from "./forms/ForgotPasswordForm";
import Footer from "./components/Footer"; // Reuse the Footer component

const ForgotPasswordPage: React.FC = () => {
  return (
    <section className="h-100">
      <div className="container h-100">
        <div className="row justify-content-sm-center h-100">
          <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
            <Header />
            <ForgotPasswordForm />
            <Footer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordPage;
