import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axiosService from "../../network/axiosService";

const ForgotPasswordForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const navigate = useNavigate();
  const validateEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setIsEmailValid(false);
      return;
    }

    axiosService.post("/password/reset_password", { email }).then(() => {
      console.log("Password Reset Email Sent", email);
      navigate("/reset_password");
    }).catch((error) => {
      console.error("Error sending password reset email", error);
    }).finally(() => {
      console.log("Password reset email request completed");
    });
  };

  return (
    <div className="card shadow-lg">
      <div className="card-body p-5">
        <h1 className="fs-4 card-title fw-bold mb-4">Forgot Password</h1>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className="mb-3">
            <label className="mb-2 text-muted" htmlFor="email">Email Address</label>
            <input
              id="email"
              type="email"
              className={`form-control ${!isEmailValid ? "is-invalid" : ""}`}
              name="email"
              value={email}
              required
              autoFocus
              onChange={handleInputChange}
            />
            {!isEmailValid && <div className="invalid-feedback">Invalid email address</div>}
          </div>

          <div className="d-flex align-items-center">
            <button type="submit" className="btn btn-primary flex-grow-1">Send Link</button>
          </div>
        </form>
      </div>
      <div className="card-footer py-3 border-0">
        <div className="text-center">
          Already have an account? <Link to="/login" className="text-dark">Login</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
