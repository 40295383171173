import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axiosService from '../../network/axiosService';
import tokenService from '../../network/tokenService';
import { useNavigate } from 'react-router-dom';


interface LoginFormState {
  email: string;
  password: string;
  isEmailValid: boolean;
  isPasswordValid: boolean;
}

const LoginForm: React.FC = () => {

  const navigate = useNavigate();

  const [formState, setFormState] = useState<LoginFormState>({
    email: '',
    password: '',
    isEmailValid: true,
    isPasswordValid: true,
  });

  const validateEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const validatePassword = (password: string) => {
    return password.length >= 6;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let isEmailValid = formState.isEmailValid;
    let isPasswordValid = formState.isPasswordValid;

    if (name === 'email') {
      isEmailValid = validateEmail(value);
    } else if (name === 'password') {
      isPasswordValid = validatePassword(value);
    }

    setFormState({
      ...formState,
      [name]: value,
      isEmailValid,
      isPasswordValid,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = validateEmail(formState.email) && validatePassword(formState.password);

    if (!isFormValid) {
      setFormState({
        ...formState,
        isEmailValid: validateEmail(formState.email),
        isPasswordValid: validatePassword(formState.password),
      });
      return;
    }

    const data = { email: formState.email, password: formState.password };

    axiosService.post('/login/basic', data)
      .then(response => {
        console.log('response', response);
        const isSuccess = response.data.statusCode === '10000';
        const tokens = response.data.data.tokens;
        if (isSuccess && response.data.data) {
          tokenService.saveTokens(tokens.accessToken, tokens.refreshToken);
          navigate('/profile');
        } else {
        }
      })
      .catch(error => {
        console.log('error', error);
        // Handle error
      })
      .finally(() => {
        console.info('request finish');
      });
  };

  return (
    <div className="card shadow-lg">
      <div className="card-body p-5">
        <h1 className="fs-4 card-title fw-bold mb-4">Login</h1>

        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className="mb-3">
            <label className="mb-2 text-muted  float-start" htmlFor="email">Email Address</label>
            <input
              id="email"
              type="email"
              className={`form-control ${!formState.isEmailValid ? 'is-invalid' : ''}`}
              name="email"
              value={formState.email}
              required
              autoFocus
              onChange={handleInputChange}
            />
            {!formState.isEmailValid && <div className="invalid-feedback">Invalid email address</div>}
          </div>

          <div className="mb-3">
            <label className="text-muted float-start" htmlFor="password">Password</label>
            <Link to="/forget_password" className="float-end text-dark">
              Forgot Password?
            </Link>
            <input
              id="password"
              type="password"
              className={`form-control ${!formState.isPasswordValid ? 'is-invalid' : ''}`}
              name="password"
              value={formState.password}
              required
              onChange={handleInputChange}
            />
            {!formState.isPasswordValid &&
              <div className="invalid-feedback">Password must be at least 6 characters long</div>}
          </div>

          <div className="d-flex bg-amber-300 align-middle ">
            {/* align the button center*/}
            <button type="submit" className="btn btn-primary ms-auto flex-grow-1 ">Login</button>
          </div>
        </form>


      </div>
      <div className="card-footer py-3 border-0">
        <div className="text-center">
          Don't have an account? <Link to="/register" className="text-dark">Create One</Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
