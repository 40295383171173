import { Octokit } from '@octokit/core';
import { FIND_USER_BY_ID_QUERY } from './queries';

const baseUrlGraphQL = 'http://localhost:5123';
const octokit = new Octokit({ baseUrl: baseUrlGraphQL });

interface ResponseData {
  findUserById: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
}

export const fetchUserById = async (userId: number): Promise<ResponseData | null> => {
  const variables = { userId };
  try {
    const response =
      await octokit.graphql<ResponseData>(FIND_USER_BY_ID_QUERY,
        variables);
    return response;
  } catch (error) {
    console.error('Error fetching user by ID', error);
    return null;
  }
};