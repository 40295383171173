import React, {useRef, useState} from 'react';
import BackPageComponent from './widgets/BackPageComponent';
import FrontPageComponent from './widgets/FrontPageComponent';
import {Dimensions, formatDate, IdCardInfo, singleNumberToDouble} from './IDCardConst';
import IDCardTranslationForm, {IDCardFormData} from './widgets/InputComponent';
import html2canvas from 'html2canvas';

const IdCardTranslation: React.FC = () => {
    const componentRefBack = useRef(null);
    const componentRefFront = useRef(null);

    const [idCardInfo, setIdCardInfo] = useState<IdCardInfo>(personData);

    const handleFormDataChange = (data: IDCardFormData) => {
        //IDCardFormData to IdCardInfo

        const dateOfBirth = formatDate(data.dateOfBirth.year, data.dateOfBirth.month, data.dateOfBirth.day);
        const startDate = `${data.validPeriod.startYear}.${singleNumberToDouble(data.validPeriod.startMonth)}.${singleNumberToDouble(data.validPeriod.startDay)}`;
        const endDate = `${data.validPeriod.endYear}.${singleNumberToDouble(data.validPeriod.endMonth)}.${singleNumberToDouble(data.validPeriod.endDay)}`;

        const newIdCardInfo: IdCardInfo = {
            name: data.name,
            sex: data.sex,
            ethnicity: data.ethnicity,
            dateOfBirth: dateOfBirth,
            address: data.address,
            citizenIdNumber: data.idNumber,
            avatar: data.avatar,
            issuingAuthority: data.issuingAuthority,
            validPeriod: startDate + ' -- ' + endDate,
        };
        setIdCardInfo(newIdCardInfo);
    };


    const handleConvertToImage = async (refObj: HTMLElement) => {
        const element = refObj;
        const images = element.getElementsByTagName('img');
        const loadPromises = Array.from(images).map((img) => {
            return new Promise<void>((resolve) => {
                if (img.complete) {
                    resolve();
                } else {
                    img.onload = () => resolve();
                    img.onerror = () => resolve();
                }
            });
        });

        await Promise.all(loadPromises);

        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = data;
            link.download = 'component.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
    };


    return (
        <div style={{display: 'flex', alignContent: 'center'}} className="justify-center">
            <IDCardTranslationForm onIDCardFormDataChange={handleFormDataChange}/>

            <div style={{marginLeft: '20px', marginRight: '20px'}}>
                <div ref={componentRefBack}>
                    <BackPageComponent cardInfo={idCardInfo}/>
                </div>

                <div style={{marginTop: '20px'}}>
                    <div ref={componentRefFront}>
                        <FrontPageComponent personInfo={idCardInfo}/>
                    </div>
                </div>

            </div>

            <div>
                <div style={{height: Dimensions.heightPx + 'px', alignContent: 'center'}}>
                    <button onClick={() => handleConvertToImage(componentRefBack.current!)}
                            style={{
                                height: Dimensions.heightPx + 'px',
                                alignContent: 'center',
                                borderRadius: '10px',
                                color: 'red',
                            }}>下载国徽页
                    </button>
                </div>
                <div style={{height: Dimensions.heightPx + 'px', alignContent: 'center', marginTop: '20px'}}>
                    <button onClick={() => handleConvertToImage(componentRefFront.current!)}
                            style={{
                                height: Dimensions.heightPx + 'px',
                                alignContent: 'center',
                                borderRadius: '10px',
                                color: 'red',
                            }}>下载国徽页
                    </button>
                </div>
            </div>
        </div>
    )
        ;
};

const personData: IdCardInfo = {
    name: 'ZHANG LAOSAN',
    sex: 'Male',
    ethnicity: 'Han',
    dateOfBirth: 'Oct 7, 1987',
    address: 'Wangcun 829, Dagangxi, Fengqiao, Zhuji, Zhejiang',
    citizenIdNumber: '330681198710076573',
    avatar: 'https://img0.baidu.com/it/u=3992066725,2379971241&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=501',
    issuingAuthority: 'Public Security Bureau of Zhuji City',
    validPeriod: '2013.10.29 -- 2033.10.29',
};


export default IdCardTranslation;
