import { useState, useEffect } from 'react';
import { fetchUserById } from '../services/api';

interface Discussion {
  id: number;
  title: string;
  author: string;
}

export const useFetchDiscussions = (userId: number) => {
  const [discussions, setDiscussions] = useState<Discussion[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDiscussions = async () => {
      const response = await fetchUserById(userId);

      if (response?.findUserById) {
        const data = response.findUserById;
        setDiscussions([{
          id: data.id,
          title: data.email,
          author: `${data.firstName} ${data.lastName}`,
        }]);
      } else {
        setError('No data found');
      }
      setIsLoading(false);
    };

    fetchDiscussions();
  }, [userId]);

  return { discussions, isLoading, error };
};