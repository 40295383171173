import React from 'react';

interface ServiceCategory {
    category: string;
    details: ServiceDetail[];
}

interface ServiceDetail {
    label: string;
    price: string | number;
    description?: string;
}

const ServiceDetailPage: React.FC = () => {
    const serviceCategories: ServiceCategory[] = [
        {
            category: 'Timed Sessions',
            details: [
                { label: '15 minutes', price: 'Not Available' },
                { label: '30 minutes', price: '$300' },
                { label: '45 minutes', price: '$320' },
                { label: '60 minutes', price: '$340' },
                { label: '90 minutes', price: '$640' },
                { label: '2 hours', price: '$680' },
                { label: '8 hours', price: 'Not Available' },
            ],
        },
        {
            category: 'Extras',
            details: [
                { label: 'Bring Your Wife', price: '$490' },
                { label: 'Waterbed Massage', price: '$390' },
            ],
        },
        {
            category: 'Additional Services',
            details: [
                { label: 'Full Service', price: 'Included' },
                { label: 'Bare Oral', price: 'Included' },
                { label: 'Second Shot', price: '+$80' },
                { label: 'Oral with Swallow', price: 'Not Available' },
                { label: 'Cum in Mouth', price: '+$50' },
                { label: 'Facial', price: 'Not Available' },
                { label: 'Rimming', price: 'Included' },
                { label: 'Anal Plug', price: '+$50' },
                { label: 'Female Clients', price: '+$150' },
                { label: 'Toys', price: '+$50' },
                { label: 'Anal', price: '+$200' },
                // The list goes on based on the example
            ],
        },
    ];

    return (
        <div className="service-detail-page max-w-3xl mx-auto p-6">
            <h2 className="text-3xl font-bold text-center mb-8">Service Details</h2>
            {serviceCategories.map((category, index) => (
                <div key={index} className="mb-8">
                    <h3 className="text-xl font-semibold text-gray-700 mb-4">{category.category}</h3>
                    <ul className="space-y-2">
                        {category.details.map((detail, idx) => (
                            <li key={idx} className="bg-gray-50 shadow rounded-lg p-4 flex justify-between">
                                <span className="text-gray-800">{detail.label}</span>
                                <span className="text-gray-600">{detail.price}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default ServiceDetailPage;
