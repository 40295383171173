import axios from 'axios';
import tokenService from './tokenService';
import config from '../../config'; // Import your token service

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: config.apiBaseUrl, // Replace with your API's base URL
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': config.apiKey,
  },
});

// Request interceptor to attach the token
axiosInstance.interceptors.request.use(config => {
  const token = tokenService.getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Response interceptor for global error handling
axiosInstance.interceptors.response.use(response => {
  // Handle responses
  return response;
}, error => {
  // Handle errors
  if (error.response?.status === 401) {
    // Optional: Redirect to log in or handle token expiration
  }
  return Promise.reject(error);
});

export default axiosInstance;

/**
 * import axiosInstance from './axiosService';
 *
 * // Example of a GET request
 * axiosInstance.get('/endpoint')
 *   .then(response => {
 *     console.log(response.data);
 *   })
 *   .catch(error => {
 *     console.error('There was an error!', error);
 *   });
 *
 * // Example of a POST request
 * axiosInstance.post('/another-endpoint', { data: 'your data' })
 *   .then(response => {
 *     console.log(response.data);
 *   })
 *   .catch(error => { console.error("There was an error!", error);   });
 *
 * */