import React from 'react';
import {Link} from 'react-router-dom';
import {FaMapMarkerAlt} from 'react-icons/fa';
import Carousel from './components/Carousel';

const avatars = [
    'https://img0.baidu.com/it/u=3992066725,2379971241&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=501',
    'https://img0.baidu.com/it/u=2020394484,4180420341&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500',
    'https://img1.baidu.com/it/u=4176902140,1808982150&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500',
    'https://img1.baidu.com/it/u=3673033527,918106645&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=750',
];

const FounderProfilePage: React.FC = () => {
    const workerId = 'sampleWorkerId'; // Replace this with the actual worker ID

    return (
        <div className="bg-amber-300 flex justify-center min-h-screen py-6">
            <div className="bg-gray-100 max-w-screen-sm w-full p-6 rounded-lg shadow-lg">
                <div key="profile-header" className="mb-4">
                    <Carousel images={avatars}/>
                </div>

                <div key="profile-info" className="mb-4 p-4 bg-white rounded-lg shadow-md text-center">
                    <p className="text-lg font-bold">Akria <span className="text-gray-600 mx-2">|</span> Canada</p>
                    <p className="mt-2">
                        <span>Cups: A</span>
                        <span className="mx-2 text-gray-600">|</span>
                        <span>Body: Slim</span>
                        <span className="mx-2 text-gray-600">|</span>
                        <span>Age: Early 20s</span>
                    </p>
                </div>

                <div key="contact-info" className="mb-4 p-4 bg-white rounded-lg shadow-md">
                    <p>Text (SMS): +16043320887</p>
                    <p>Telegram: @akira</p>
                    <p>X: @akira</p>
                </div>

                {/* Sleek and Clean Location Section */}
                <div id="location" className="mb-4 p-4 bg-white rounded-lg shadow-md">
                    <div className="flex items-center">
                        <FaMapMarkerAlt className="text-red-500 mr-2" size={18}/>
                        <div>
                            <p className="text-base font-medium">Metrotown Burnaby</p>
                            <p className="text-sm text-gray-500">Transport: Metrotown</p>
                        </div>
                    </div>
                </div>

                <div id="service-info"
                     className="mb-4 p-4 bg-white rounded-lg shadow-md flex justify-between items-center">
                    <text className="font-semibold"> 30min ($260); 45min ($280); 1h ($320)</text>
                    <Link to={`/sex-worker/${workerId}/services`}>
                        <button className="bg-cyan-500 hover:bg-cyan-600 text-white font-semibold py-2 px-4 rounded-xl">
                            Services
                        </button>
                    </Link>
                </div>

                <div id="service-calendar"
                     className="mb-4 p-4 bg-white rounded-lg shadow-md flex justify-between items-center">
                    <div>
                        <h6 className="font-semibold">Available Today:</h6>
                    </div>
                    <button className="bg-cyan-500 hover:bg-cyan-600 text-white font-semibold py-2 px-4 rounded-xl">
                        Calendar
                    </button>
                </div>

                <div key={'review'} className="mb-4 p-4 bg-white rounded-lg shadow-md">
                    <div className="flex justify-between">
                        <h6 className="font-semibold">Review</h6>
                        <p>2024.09.24 — Rating: 5/5</p>
                    </div>
                    <p id={'review_content'} className="mt-2 text-left text-ellipsis line-clamp-2">
                        She's a very down-to-earth gal. Your girl next door... I can't believe she's so pretty kind of
                        girl.
                        Enjoyed talking to her as much as doing the other stuff...
                    </p>
                    <Link to={`/sex-worker/${workerId}/comments`} className="text-right text-blue-500">
                        More Reviews
                    </Link>
                </div>

                <div key="safety" className="p-4 bg-white rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold">Health and Safety Info</h3>
                </div>
            </div>
        </div>
    );
};

export default FounderProfilePage;
