import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className="text-center mt-5 text-muted">
      Copyright &copy; 2017-2024 &mdash; GrandLine
    </div>
  );
}

export default Footer;
