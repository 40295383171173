import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import UserComment, {UserCommentProps} from "./components/UserComment";

const CommentListPage: React.FC = () => {
    const {workerId} = useParams<Record<string, string | undefined>>(); // With parameter typing

    const [comments, setComments] = useState<UserCommentProps[]>([]);

    useEffect(() => {
        if (!workerId) return; // Ensure workerId is defined

        const fetchComments = async () => {
            try {
                const response = await axios.get<UserCommentProps[]>(`/api/sex-worker/${workerId}/comments`);
                setComments(response.data);
            } catch (error) {
                console.error('Error fetching comments:', error);
                // If debugging, add a variety of fake data
                setComments([
                    {
                        id: 1,
                        text: 'Fantastic experience! I felt very comfortable and at ease throughout. Highly recommended!',
                        author: 'Alice',
                        date: '2024-10-01',
                        score: 5,
                    },
                    {
                        id: 2,
                        text: 'Very professional and friendly.',
                        author: 'Bob',
                        date: '2024-10-02',
                        score: 4,
                    },
                    // Additional fake comments...
                ]);
            }
        };

        fetchComments();
    }, [workerId]);

    return (
        <div className="comment-list-page max-w-2xl mx-auto p-4">
            <h2 className="text-2xl font-bold text-center mb-6">Comments for Worker ID: {workerId}</h2>
            <ul className="comments space-y-4">
                {comments.map(comment => (
                    <UserComment key={comment.id} {...comment} />
                ))}
            </ul>
        </div>
    );
};

export default CommentListPage;
