import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface CarouselProps {
    images: string[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState<"left" | "right">("right");
    const intervalDelay = 3000;

    const handleNext = () => {
        setDirection("right");
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevious = () => {
        setDirection("left");
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleDotClick = (index: number) => {
        setDirection(index > currentIndex ? "right" : "left");
        setCurrentIndex(index);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setDirection("right");
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, intervalDelay);

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div className="relative w-full h-[300px] overflow-hidden">
            <AnimatePresence custom={direction}>
                <motion.img
                    key={currentIndex}
                    src={images[currentIndex]}
                    initial={{ x: direction === "right" ? "100%" : "-100%", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: direction === "right" ? "-100%" : "100%", opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="w-full h-full object-cover"
                    alt={`Slide ${currentIndex}`}
                />
            </AnimatePresence>
            <div className="absolute bottom-0 w-full flex justify-center mb-4">
                {images.map((_, index) => (
                    <span
                        key={index}
                        onClick={() => handleDotClick(index)}
                        className={`w-3 h-3 mx-2 mb-0.5 rounded-full cursor-pointer ${
                            currentIndex === index ? 'bg-black' : 'bg-gray-300'
                        }`}
                    ></span>
                ))}
            </div>
            <div className="absolute top-1/2 w-full flex justify-between transform -translate-y-1/2">
                <button
                    onClick={handlePrevious}
                    className="bg-black bg-opacity-50 text-xs text-white rounded-full px-2 py-1 mx-1 transition-transform transform hover:scale-110"
                    // Prevent default action in case of triggering unexpected behavior
                    onMouseDown={(e) => e.preventDefault()}
                >
                    Prev
                </button>
                <button
                    onClick={handleNext}
                    className="bg-black bg-opacity-50 text-xs text-white rounded-full px-2 py-1 mx-1 transition-transform transform hover:scale-110"
                    onMouseDown={(e) => e.preventDefault()}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default Carousel;
