import React, { useState } from "react";

interface ResetPasswordFormState {
  password: string;
  passwordConfirm: string;
  logoutDevices: boolean;
  isPasswordValid: boolean;
  isPasswordConfirmValid: boolean;
}

const ResetPasswordForm: React.FC = () => {
  const [formState, setFormState] = useState<ResetPasswordFormState>({
    password: "",
    passwordConfirm: "",
    logoutDevices: false,
    isPasswordValid: true,
    isPasswordConfirmValid: true
  });

  const validatePassword = (password: string) => {
    return password.length >= 6;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    let isPasswordValid = formState.isPasswordValid;
    let isPasswordConfirmValid = formState.isPasswordConfirmValid;

    if (name === "password") {
      isPasswordValid = validatePassword(value);
    } else if (name === "passwordConfirm") {
      isPasswordConfirmValid = value === formState.password;
    }

    setFormState({
      ...formState,
      [name]: type === "checkbox" ? checked : value,
      isPasswordValid,
      isPasswordConfirmValid
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isFormValid = formState.isPasswordValid && formState.isPasswordConfirmValid;

    if (!isFormValid) {
      // Update validation state
      setFormState({
        ...formState,
        isPasswordValid: validatePassword(formState.password),
        isPasswordConfirmValid: formState.password === formState.passwordConfirm
      });
      return;
    }

    // Implement password reset logic here
    console.log("Password Reset Form Submitted", formState);
  };

  return (
    <div className="card shadow-lg">
      <div className="card-body p-5">
        <h1 className="fs-4 card-title fw-bold mb-4">Reset Password</h1>
        <form method="POST" className="needs-validation" onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className="mb-3">
            <label className="mb-2 text-muted" htmlFor="password">New Password</label>
            <input id="password" type="password" className="form-control" name="password" value={formState.password}
                   required autoFocus onChange={handleInputChange} />
            <div className="invalid-feedback">
              Password is required
            </div>
          </div>

          <div className="mb-3">
            <label className="mb-2 text-muted" htmlFor="password-confirm">Confirm Password</label>
            <input id="password-confirm" type="password" className="form-control" name="passwordConfirm"
                   value={formState.passwordConfirm} required onChange={handleInputChange} />
            <div className="invalid-feedback">
              Please confirm your new password
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="form-check">
              <input type="checkbox" name="logoutDevices" id="logout" className="form-check-input"
                     checked={formState.logoutDevices} onChange={handleInputChange} />
              <label htmlFor="logout" className="form-check-label">Logout all devices</label>
            </div>
            <button type="submit" className="btn btn-primary flex-grow-1">Reset Password</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
